footer {
  height: 60px;
  text-align: center;
}

footer a, footer a:hover {
  color: inherit;
}

footer p {
  line-height: 60px;;
}