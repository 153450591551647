.App {
  height: 100%;
}

.container {
  /* equal to footer height */
  margin-bottom: -60px; 
}

#brand i {
  font-size: 20px;
}

.container:after {
  content: "";
  display: block;
  height: 60px;
}

#app-header {
  padding-top: 25px;
  margin-bottom: 25px;
}

#github-link-wrap a {
  font-size: 25px;
  color: inherit;
}